// App.js
import React from 'react';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import { theme } from './theme';
import Hero from './components/Hero';
import ParticlesBackground from './components/ParticlesBackground';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ParticlesBackground />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Hero />
      </Box>
    </ThemeProvider>
  );
}

export default App;
