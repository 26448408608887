// theme.js
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'dark', // Change to 'light' if needed
    primary: {
      main: '#1976d2', // Adjust as per your brand color
    },
    background: {
      default: '#000000', // Black for dark mode
      paper: '#1E1E1E', // Slightly lighter for paper components
    },
    text: {
      primary: '#ffffff', // White text
      secondary: '#B0B0B0', // Light gray for secondary text
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Disable uppercase transformation
          fontWeight: 600, // Semi-bold text
        },
      },
    },
  },
});
