// components/Hero.js
import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Define mobile as 'sm' and below

  return (
    <Box 
      sx={{ 
        position: 'relative', 
        minHeight: '80vh', // Adjusted height to prevent pushing content below
        display: 'flex', 
        alignItems: isMobile ? 'flex-start' : 'center', // Align to top on mobile
        justifyContent: 'center', // Center content horizontally
        overflow: 'hidden',
        backgroundColor: 'transparent', // Background handled globally
        pt: isMobile ? 8 : 0, // Adjusted padding-top
        pb: isMobile ? 8 : 0, // Optional padding-bottom on mobile
      }}
    >
      <Container 
        maxWidth="lg" 
        sx={{ 
          position: 'relative', 
          zIndex: 1, // Above the grid
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            textAlign: 'center',
            color: '#fff', // Ensure text is visible on black background
          }}
        >
          <Typography
            variant={isMobile ? 'h4' : 'h2'} // Responsive typography
            sx={{
              mt: 5,
              mb: 3,
              fontWeight: 800,
              background: 'linear-gradient(to right, #60a5fa, #8b5cf6)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Train Your Coding LLM and Agent
            <br />
            with SOTA Code Reasoning Data
          </Typography>
          
          <Typography 
            variant={isMobile ? 'h6' : 'h4'} 
            sx={{ 
              maxWidth: 'xl', // Increased maxWidth for better readability
              mx: 'auto', 
              mb: 4, // Reduced margin to accommodate image
              px: isMobile ? 2 : 0, // Horizontal padding on mobile
            }}
          >
            <br />
            We collect high-quality code reasoning data from talented engineers,
            capturing their step-by-step thought process from incorrect to correct code.
            <br /> <br />
            Our sequential datasets help AI labs and companies train more effective coding models
            by teaching them how expert programmers actually think.
          </Typography>

          <Box
            component="img"
            src="/icon.png"
            alt="PlayDo AI Icon"
            sx={{
              width: isMobile ? '150px' : '200px',
              height: 'auto',
              mb: 8,
              filter: 'drop-shadow(0 0 10px rgba(96, 165, 250, 0.5))'
            }}
          />
          
          <Button 
            variant="contained" 
            size="large"
            href="https://calendly.com/jessechoe10/playdoai" // Schedule call link
            sx={{
              backgroundColor: '#fff',
              color: '#000',
              paddingX: 3,
              paddingY: 1.5,
              fontWeight: 600,
              borderRadius: '8px',
              textTransform: 'none', // Maintain capitalization
              width: isMobile ? '100%' : 'auto',
              maxWidth: isMobile ? '100%' : '300px', // Set max width
              '&:hover': {
                backgroundColor: '#60a5fa', // Hover color
              },
            }}
          >
            Schedule a Call
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
